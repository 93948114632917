// images
import TopBackground from "../images/mango-02.jpeg";
import Utm from "../components/_global/utm";

// icons
import SignupIcon from "../images/icon/signupIcon.svg";
import TshirtIcon from "../images/icon/tshirtIcon.svg";
import LoginIcon from "../images/icon/loginIcon.svg";
import WorldIcon from "../images/icon/worldIcon.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("mango-x-foursource.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("mango-x-foursource.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("mango-x-foursource.signupFree"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(t("mango-x-foursource.signupLink")),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      notFull70: true,
      image: {
        src: TopBackground,
        alt: t("mango-x-foursource.imgAlt"),
        centerPosition: true,
      },
    },
    firstText: [
      {
        text: t("mango-x-foursource.foursourceMango"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "font20 lineHeight1_7 letterSpacing1 fontWeight400",
      },
    ],
    startedProcessTitle: {
      title: {
        text: t("mango-x-foursource.howDoes"),
        colorClass: "colorDark",
        extraClass: "letterSpacing3 font34",
      },
      textCenter: true,
      separator: true,
      topDiagonal: false,
      backgroundGrey: false,
    },
    startedProcess: [
      {
        icon: {
          src: SignupIcon,
          alt: t("mango-x-foursource.signupAlt"),
        },
        title: t("mango-x-foursource.signupTitle"),
        text: t("mango-x-foursource.signupText"),
        before: true,
        href: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(t("mango-x-foursource.signupLink")),
      },
      {
        icon: {
          src: LoginIcon,
          alt: t("mango-x-foursource.profileAlt"),
        },
        title: t("mango-x-foursource.profileTitle"),
        text: t("mango-x-foursource.profileText"),
        before: true,
      },
      {
        icon: {
          src: TshirtIcon,
          alt: t("mango-x-foursource.certsAlt"),
        },
        title: t("mango-x-foursource.certsTitle"),
        text: t("mango-x-foursource.certsText"),
        before: true,
      },
      {
        icon: {
          src: WorldIcon,
          alt: t("mango-x-foursource.evaluationAlt"),
        },
        title: t("mango-x-foursource.evaluationTitle"),
        text: t("mango-x-foursource.evaluationText"),
        before: false,
        href: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(t("mango-x-foursource.signupLink")),
      },
    ],
    whatIs: {
      title: {
        text: t("mango-x-foursource.whatIs"),
        colorClass: "colorDark",
        extraClass: "letterSpacing3 font34",
      },
      text: {
        text: t("mango-x-foursource.whatIsText"),
        colorClass: "colorDark",
        extraClass: "font20 lineHeight1_7 letterSpacing1 fontWeight400",
      },
      textCenter: true,
      separator: false,
      topDiagonal: false,
      backgroundGrey: false,
    },

    agencyPolicy: {
        title: {
          text: t("mango-x-foursource.agencyPolicy"),
          colorClass: "colorDark",
          extraClass: "letterSpacing3",
        },
        text: {
          text: t("mango-x-foursource.agencyPolicyText"),
          colorClass: "colorDark",
          extraClass: "font20 lineHeight1_7 letterSpacing1 fontWeight400",
        },
        textCenter: true,
        separator: false,
        topDiagonal: false,
        backgroundGrey: false,
      },
  };
};
